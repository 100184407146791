import { render, staticRenderFns } from "./companyCulture.vue?vue&type=template&id=2fdac067&scoped=true&"
import script from "./companyCulture.vue?vue&type=script&lang=js&"
export * from "./companyCulture.vue?vue&type=script&lang=js&"
import style0 from "./companyCulture.vue?vue&type=style&index=0&id=2fdac067&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fdac067",
  null
  
)

export default component.exports